import React from 'react'
import {Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home/Home";
import About from "./About/About";
import Portfolio from "./Portfolio/Portfolio";
import Services from "./Services/Services";
import Clients from "./Clients/Clients";
import WhyUs from "./WhyUs/WhyUs";
import Contact from "./Contact/Contact";
import { AnimatePresence } from "framer-motion";
import Residential from './Portfolio/Residential/Residential';
import Commercial from './Portfolio/Commercial/Commercial';
import Hospitality from './Portfolio/Hospitality/Hospitality';
import CorporateOffice from './Portfolio/CorporateOffice/CorporateOffice';
import RetailShowroom from './Portfolio/RetailShowroom/RetailShowroom';
import ModularKitchens from './Portfolio/ModularKitchens/ModularKitchens';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';




const AnimatedRoutes = () => {

const location = useLocation();
  
  return (
    <AnimatePresence>
    <Routes location={location} key={location.pathname}>
    <Route path="/" exact element={<Home />}></Route>
    <Route path="/About" exact element={<About />}></Route>
    <Route path="/Portfolio" exact element={<Portfolio />}></Route>
    <Route path="/Services" exact element={<Services />}></Route>
    <Route path="/Clients" exact element={<Clients />}></Route>
    <Route path="/Why-Us" exact element={<WhyUs />}></Route>
    <Route path="/Contact" exact element={<Contact />}></Route>
    <Route path="Portfolio/Residential" exact element={<Residential />}></Route>
    <Route path="Portfolio/Hospitality" exact element={<Hospitality />}></Route>
    <Route path="Portfolio/Commercial" exact element={<Commercial />}></Route>
    <Route path="Portfolio/Corporate-Office" exact element={<CorporateOffice />}></Route>
    <Route path="Portfolio/Retail-Showroom" exact element={<RetailShowroom />}></Route>
    <Route path="Portfolio/Modular-Kitchens" exact element={<ModularKitchens />}></Route>
    <Route path="Privacy-Policy" exact element={<PrivacyPolicy />}></Route>

       </Routes>
    
     </AnimatePresence>
  )
}

export default AnimatedRoutes
