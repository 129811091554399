import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../WhatWeDo/WhatWeDo.css";
import OwlCarousel from "react-owl-carousel";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const WhatWeDo = () => {

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();


  useEffect(() => {
  

    if (inView) {
      animation.start({
        y: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 3
        },
      });
    }
    if (!inView) {
          animation.start({
        y: '5vw',
        opacity:0

      });

    
    }


  }, [inView]);




  
  //Owl Carousel Settings
  const options = {
    loop: false,
    margin: 0,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    navText: [
      "<i className='fa fa-caret-left'></i>",
      "<i className='fa fa-caret-right'></i>",
    ],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <React.Fragment>
      <Container className="py-4" ref={ref}>
        <Row>
          <Col lg="12">
            <div className="heading_divider1 text-center mb-4 ">
              <h6 className="letter_spacing">WHAT WE DO</h6>
              <h2>Our Services</h2>
            </div>
            
          </Col>

          <Col lg="12">
            <motion.div 
             animate={animation}
            className="our_services">
              <OwlCarousel
                className="own_testimonials owl-carousel owl-theme"
                loop
                margin={10}
                {...options}
              >
                <div className="item ">
                  <div className="elementor-cta__content ">
                    <motion.div 
                     animate={animation}
                    className="what_we_do">
                      
                      <div className="wwd_content">
                        <h2 className="mb-0">01</h2>
                        <h5 className="mb-2">Space Planning</h5>
                        <p className="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do labore et dolore aliqua.
                        </p>
                        </div>
                    </motion.div>
                  </div>
                </div>

                <div className="item">
                <div className="elementor-cta__content">
                            <motion.div 
                              animate={animation}
                            className="what_we_do">
                                <div className="wwd_content">
                                    <h2 className="mb-0">02</h2>
                                    <h5 className="mb-2">Interior Consultancy</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do labore et dolore
                                        aliqua. </p>
                                </div>
                            </motion.div>
                        </div>



                </div>

                <div className="item">
                <div className="elementor-cta__content">
                            <motion.div 
                              animate={animation}
                            className="what_we_do">
                                <div className="wwd_content">
                                    <h2 className="mb-0">03</h2>
                                    <h5 className="mb-2">Design Build</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do labore et dolore
                                        aliqua. </p>

                                </div>
                            </motion.div>
                        </div>

                </div>

                <div className="item">

                <div className="elementor-cta__content">
                            <motion.div 
                              animate={animation}
                            className="what_we_do">
                                <div className="wwd_content">
                                    <h2 className="mb-0">04</h2>
                                    <h5 className="mb-2">Decor</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do labore et dolore
                                        aliqua. </p>
                                </div>
                            </motion.div>
                        </div>

                </div>
              </OwlCarousel>
            </motion.div>
          </Col>


          <Col lg="12" className="text-center mt-4">
          <a href="/" className="readMore ms-2 d-inline-block mt-2" >Read More</a>
          </Col>






        </Row>
      </Container>
    </React.Fragment>
  );
};

export default WhatWeDo;
