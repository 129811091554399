import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResidentialImg01 from "../../../images/residential/residential-img01.jpg";
import ResidentialImg02 from "../../../images/residential/residential-img02.jpg";
import ResidentialImg03 from "../../../images/residential/residential-img03.jpg";
import ResidentialImg04 from "../../../images/residential/residential-img04.jpg";
import ResidentialImg05 from "../../../images/residential/residential-img05.jpg";
import ResidentialImg06 from "../../../images/residential/residential-img06.jpg";
import {SlideshowLightbox} from 'lightbox.js-react';


import Breadcrumb from "react-bootstrap/Breadcrumb";

const Commercial = () => {
  useEffect(() => {
    document.title = "Commercial - Portfolio - Luxuria Home Decor";
  }, []);

  return (
    <React.Fragment>

<Container className=" pt-4">
      <Row>
      <Col lg={12} className=' d-flex flex-column flex-lg-row  align-items-center justify-content-between'>
      <div>  <h2>Portfolio - Commercial</h2></div>
      <div>
      <Breadcrumb className="  ps-3 pe-3">
                  <Breadcrumb.Item active>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Portfolio</Breadcrumb.Item>
                  <Breadcrumb.Item active>Commercial</Breadcrumb.Item>
                </Breadcrumb>

      </div>

        </Col>
        </Row>
        </Container>

    <hr />


     <SlideshowLightbox className="container row-cols-1 row-cols-sm-2 g-2 mx-auto">
    
     <img className="img-fluid p-2" src={ResidentialImg01}  />
     <img className="img-fluid p-2" src={ResidentialImg02}  />
     <img className="img-fluid p-2" src={ResidentialImg03}  />
     <img className="img-fluid p-2" src={ResidentialImg04}  />
     <img className="img-fluid p-2" src={ResidentialImg05}  />
     <img className="img-fluid p-2" src={ResidentialImg06}  />


</SlideshowLightbox> 






    </React.Fragment>
  )
}

export default Commercial
