import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "../WhyChoose/WhyChoose.css";
import CaeerIcon from "../../images/career.png";
import PriceIcon from "../../images/price_icon.png";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const WhyChoose = () => {


  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();


  useEffect(() => {
    // console.log("useEffect hook, inView =", inView);

    if (inView) {
      animation.start({
        y: 10, 
        opacity: 1,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.2,
        },  exit:{ y: -10, opacity: 0 }
            });


    

    }
    if (!inView) {
          animation.start({
            y: 0, opacity: 0

      })



    }


  }, [inView]);




  return (
    <>
      <Container className="">
        <Row>

        <Col lg="12" className="text-center heading_divider">
        <h2>Why choose us</h2>
        </Col>

          <Col lg="12" ref={ref}>
        <motion.div
         animate={animation}
         
        >
          <Tabs
      defaultActiveKey="price"
      transition={false}
      id="noanim-tab-example"
      className="mb-3 d-flex justify-content-center custom_tab "
    >
      <Tab eventKey="price" title={<span> <img src={CaeerIcon} alt="Price"  /> Price</span>}>
        <div className="d-flex justify-content-start align-items-center">
            <div className="me-3">
              <img src={PriceIcon} alt="PriceIcon"  />
            </div>
            <div>
       
            <h4> Price</h4>
          <p>No hidden cost whatever is there we share that transparently</p>
     
            </div>
        </div>

      </Tab>
      <Tab eventKey="convenience" title={<span> <img src={CaeerIcon} alt="Convenience"  /> Convenience</span>}>

      <h4> Convenience</h4>
      <p>Convenience</p>
      
      </Tab>
      <Tab eventKey="design"  title={<span><img src={CaeerIcon} alt="Design"  /> Design</span>}>
      <h4> Design</h4>
        <p>Design</p>
      </Tab>

      <Tab eventKey="timelines" title={<span> <img src={CaeerIcon} alt="Timelines"  /> Timelines</span>}>

      <h4> Timelines</h4>
        <p>Timelines</p>
      </Tab>

      <Tab eventKey="quality"  title={<span> <img src={CaeerIcon} alt="Quality"  /> Quality</span>}>
      <h4> Quality</h4>
        <p>Quality</p>
      </Tab>

    </Tabs>
</motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhyChoose;
