import React from 'react'
import "../Footer/Footer.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterLogo from "../../images/logo.svg"
import { NavLink } from "react-router-dom";


const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
  return (
    <>

<section className="call_bg mt-3">
    <Container>
    <Row>
        <Col lg="12" className='py-2'>
        <h2 className='text-white text-center pt-2 '>Call Us +91 7718960701</h2>
        </Col>   
    </Row>
    </Container>
</section>



<section id='footer'>
 <Container>
    <Row>
     <Col lg="12" className='text-center mt-4'>
            <div><img src={FooterLogo} alt=""  /></div>

            <div className='mt-3'>
            <ul className="nav justify-content-center">
                <li className="nav-item">
                    <NavLink to="/About" onClick={scrollToTop} className="nav-link">About</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/Portfolio/Residential" onClick={scrollToTop}  className="nav-link">Portfolio</NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/Services" onClick={scrollToTop}  className="nav-link">Services</NavLink>
                </li>

                <li className="nav-item">
                <NavLink to="/Clients" onClick={scrollToTop}  className="nav-link">Clients</NavLink>

                </li>

                <li className="nav-item">
                <NavLink to="/WhyUs" onClick={scrollToTop}  className="nav-link">Why Us?</NavLink>
                </li>

                <li className="nav-item">
                <NavLink to="/Contact" onClick={scrollToTop}  className="nav-link">Contact</NavLink>
                </li>

                <li className="nav-item">
                <NavLink to="/Privacy-Policy" onClick={scrollToTop}  className="nav-link">Privacy Policy</NavLink>
                </li>
            </ul>

        </div>
    </Col>
    </Row>
    </Container>
    </section>   

<section id='copy-right'>

<div className="copy_right border-top ">
            <p className=" mb-0 small py-2 text-center"> Copyright © 2023 Luxuria Home Decor All Rights Reserved </p>

        </div>





</section>
     
    </>
  )
}

export default Footer
