import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";


import SliderThree from "../../../images/slider-03.jpg";


import BeforeUseImage from "../../../images/before.jpg";
import AfterUseImage from "../../../images/slider-01.jpg";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import Breadcrumb from "react-bootstrap/Breadcrumb";

const Residential = () => {
  useEffect(() => {
    document.title = "Residential - Portfolio - Luxuria Home Decor";
  }, []);

  return (
    <React.Fragment>

<Container className="residential pt-4">
      <Row>
      <Col lg={12} className=' d-flex flex-column flex-lg-row align-items-center justify-content-between'>
      <div>  <h2>Portfolio - Residential</h2></div>
      <div>
      <Breadcrumb className=" ps-3 pe-3">
                  <Breadcrumb.Item active>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Portfolio</Breadcrumb.Item>
                  <Breadcrumb.Item active>Residential</Breadcrumb.Item>
                </Breadcrumb>

      </div>

        </Col>
        </Row>
        </Container>

    <hr />



    <Container className="mb-5">
      <Row className=''>
      <Col lg={12}>
    

<Carousel fade>

      <Carousel.Item>
      <ReactCompareSlider
  itemOne={<ReactCompareSliderImage src={BeforeUseImage} srcSet={BeforeUseImage} alt="Image one" />}
  itemTwo={<ReactCompareSliderImage src={AfterUseImage} srcSet={AfterUseImage} alt="Image two" />}
/>
      </Carousel.Item>
    
      <Carousel.Item>
      <img src={SliderThree} alt="" className="d-block w-100" />
      </Carousel.Item>



      <Carousel.Item>
      <ReactCompareSlider
  itemOne={<ReactCompareSliderImage src={BeforeUseImage} srcSet={BeforeUseImage} alt="Image one" />}
  itemTwo={<ReactCompareSliderImage src={AfterUseImage} srcSet={AfterUseImage} alt="Image two" />}
/>
      </Carousel.Item>
    </Carousel>



      </Col>
      </Row>
     </Container>
    </React.Fragment>
  )
}

export default Residential
