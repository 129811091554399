import { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutBanner from "../../images/about-banner.jpg";
import InteriorImg01 from "../../images/interior-drawing-01.jpg";
import InteriorImg02 from "../../images/interior-drawing-02.jpg";
import "../About/About.css";
import Accordion from "react-bootstrap/Accordion";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { RiHome8Fill } from "react-icons/ri";

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

const About = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();
  const myanimation = useAnimation();

  useEffect(() => {
    // console.log("useEffect hook, inView =", inView);

    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.2,
        },
      });

      myanimation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-10vw",
        opacity: 0,
      });

      myanimation.start({
        x: "10vw",
        opacity: 0,
      });
    }
  });
  // [inView]

  useEffect(() => {
    document.title = "About - Luxuria Home Decor";
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Container fluid className=" px-4 px-lg-5 mb-3 ">
          <Row
            className="about_bannerBg g-0  d-flex align-items-center position-relative"
            ref={ref}
          >
            <Col lg={4} className="h-100  align-items-center">
              <motion.div animate={animation} className="ps-5 text-white">
                <div className="d-none d-lg-block ">
                <h1> About</h1>
                <h3 className="fw-light">
                  We focus on quality and <br />
                  excellent design service
                </h3>
                </div>
              </motion.div>

              <div className="position-absolute bottom-0 start-0 breadcrumb-bg">
                <Breadcrumb className=" pt-3 ps-3 pe-3">
                  <Breadcrumb.Item href="#">
                    <RiHome8Fill />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>About</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
            <Col lg={8} className="">
              <motion.div
                initial={false}
                animate={
                  isLoaded && isInView
                    ? {
                        WebkitMaskImage: visibleMask,
                        maskImage: visibleMask,
                      }
                    : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsInView(true)}
              >
                <img
                  onLoad={() => setIsLoaded(true)}
                  src={AboutBanner}
                  alt=""
                  className="w-100 img-fluid"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>

        <Container className="aboutData px-4 px-lg-5">
          <Row className="d-flex align-items-center">
            <Col lg={6} className="order-1">
                  <div >
              <h4>Our Story</h4>
              <h2>Luxuria Home Decor</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra labore
                et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>
</div>
            </Col>
            <Col lg={6} className="order-2">
              <motion.div
                initial={false}
                animate={
                  isLoaded && isInView
                    ? {
                        WebkitMaskImage: visibleMask,
                        maskImage: visibleMask,
                      }
                    : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsInView(true)}
              >
                <img
                  onLoad={() => setIsLoaded(true)}
                  src={InteriorImg01}
                  alt=""
                  className="img-fluid"
                />
              </motion.div>
            </Col>
          </Row>

          <Row className="d-flex align-items-center border-top">
            <Col lg={6}  className=" order-lg-1 order-sm-2 ">
              <motion.div
                initial={false}
                animate={
                  isLoaded && isInView
                    ? {
                        WebkitMaskImage: visibleMask,
                        maskImage: visibleMask,
                      }
                    : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsInView(true)}
               
              >
                <img
                  onLoad={() => setIsLoaded(true)}
                  src={InteriorImg02}
                  alt=""
                  className="img-fluid "
                />
              </motion.div>
            </Col>

            <Col lg={6} className="order-2  order-sm-1 ">
              <div className="mt-sm-4">
                <h4>What We Do</h4>
                <h2>Interior Design Transformations</h2>
                <p>
                  We consider involving & evolving of good planning comes right
                  from inception of a idea till breathing in the structure. we
                  do not plan in order to have clients, we have clients in order
                  to plan
                </p>
              </div>
            </Col>
          </Row>

          <Col lg={12}>
            <Accordion className="custom-accordian">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Our Principles</Accordion.Header>

                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Our Strategy</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Customer Solutions</Accordion.Header>
                <Accordion.Body>
                  We treat our customers the way we expect to be treated.
                  Customer solutions and services are considered as a by-product
                  of our professional services, with a smile of happiness.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Container>
      </motion.div>
    </>
  );
};

export default About;
