import React, {useState} from "react";
import Header from "./components/Header/Header";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./NavbarComp.css";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Logo from "./images/logo.svg";
import { RiHome8Fill } from 'react-icons/ri';
import NavDropdown from 'react-bootstrap/NavDropdown';

import "./NavbarComp.css";
import Footer from "./components/Footer/Footer";

import AnimatedRoutes from "./components/AnimatedRoutes";
import {motion } from "framer-motion";



const NavbarComp = () => {
  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = (e) => {
      setShow(false);
  }
  
  // const [show, setShow] = useState(false);
  // const [sshow, setsShow] = useState(false);












  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Router>

        <Header />

        {["xl"].map((expand) => (
          <Navbar key={expand} expand={expand} className="pt-0 pb-0 sticky-top">
            <Container fluid className="bg-white px-4 px-lg-5 border-bottom">
          
              <Navbar.Brand href="/" className="">
                <motion.div
                whileHover={{scale:1.05}}
                >
                <img src={Logo} alt="" />
                </motion.div>
              </Navbar.Brand>
           
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                restoreFocus={false} 
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1">
                    <Nav.Link
                      as={Link}
                      to="/"
                      activeclassname="active"
                      eventKey="/"
                    
                    >
                     <RiHome8Fill className="HomeIcon" />

                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/About"
                      activeclassname="active"
                      eventKey="/About"
                      onClick={scrollToTop}
                    >
                     About
                    </Nav.Link>

                    <NavDropdown 
                   menuVariant="dark"
                  show={show}
                  onMouseEnter={showDropdown} 
                  onMouseLeave={hideDropdown}
               title="Portfolio" 
               id="basic-nav-dropdown"   
               activeclassname="active"
               >
              <NavDropdown.Item as={Link} to="/Portfolio/Residential"  eventKey="/Portfolio/Residential" activeclassname="active"  onClick={scrollToTop}>Residential</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/Portfolio/Commercial" eventKey="/Portfolio/Commercial" activeclassname="active"  onClick={scrollToTop}>Commercial</NavDropdown.Item>                
              <NavDropdown.Item as={Link} to="/Portfolio/Hospitality" eventKey="/Portfolio/Hospitality" activeclassname="active"  onClick={scrollToTop}>Hospitality</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Portfolio/Corporate-Office" eventKey="/Portfolio/Corporate-Office" activeclassname="active" onClick={scrollToTop}>Corporate Office</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Portfolio/Retail-Showroom" eventKey="/Portfolio/Retail-Showroom" activeclassname="active" onClick={scrollToTop}>Retail Showroom </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Portfolio/Modular-Kitchens" eventKey="/Portfolio/Modular-Kitchens" activeclassname="active" onClick={scrollToTop}>Modular Kitchens </NavDropdown.Item>
            </NavDropdown>


                   <Nav.Link
                      as={Link}
                      to="/Services"
                      activeclassname="active"
                      eventKey="/Services"
                      onClick={scrollToTop}
                    >
                   Services
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/Clients"
                      activeclassname="active"
                      eventKey="/Clients"
                      onClick={scrollToTop}
                    >
                  Clients
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/Why-Us"
                      activeclassname="active"
                      eventKey="/Why-Us"
                      onClick={scrollToTop}
                    >
                 Why Us?
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/Contact"
                      activeclassname="active"
                      eventKey="/Contact"
                      onClick={scrollToTop}
                    >
                 Contact
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}

    <AnimatedRoutes/>
          <Footer/>
      </Router>
    </>
  );
};

export default NavbarComp;
