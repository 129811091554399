import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuoteIcon from "../../images/quote_icon.jpg";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const TestimonialsDiv = () => {


  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();


  useEffect(() => {
  

    if (inView) {
      animation.start({
        y: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 3
        },
      });
    }
    if (!inView) {
          animation.start({
        y: '5vw',
        opacity:0

      });

    
    }


  }, [inView]);






  //Owl Carousel Settings
  const options = {
    loop: false,
    margin: 20,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    navText: [
      "<i className='fa fa-caret-left'></i>",
      "<i className='fa fa-caret-right'></i>",
    ],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  return (
    <>
      <Container className="py-4">
        <Row>
          <Col lg="12">
            <div className="heading_divider1 text-center mb-2 ">
              <h6 className="letter_spacing">Testimonials</h6>
              <h2>What Our Clients Say</h2>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="" ref={ref}>
        <Row>
          <Col lg="12">
            <motion.div
             animate={animation}
            >

            <OwlCarousel
              className="owl-theme own_testimonialss"
              loop
              margin={10}
              nav
              {...options}
            >
              <div className="item p-5">
                <span className="qoutes_box d-flex justify-content-center align-items-center">
                  <img src={QuoteIcon} alt="QuoteIcon" />
                </span>

                <p className="text-white pt-3">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. "
                </p>
                <h5 className="mb-0 pb-0 text-white ">Palio Mehta</h5>
                <p className="mb-0 pb-0 text-white">Co, Founder</p>
              </div>
              
              <div className="item p-5">
                <span className="qoutes_box d-flex justify-content-center align-items-center">
                  <img src={QuoteIcon} alt="QuoteIcon" />
                </span>

                <p className="text-white pt-3">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. "
                </p>
                <h5 className="mb-0 pb-0 text-white ">Palio Mehta</h5>
                <p className="mb-0 pb-0 text-white">Co, Founder</p>
              </div>

              <div className="item p-5">
                <span className="qoutes_box d-flex justify-content-center align-items-center">
                  <img src={QuoteIcon} alt="QuoteIcon" />
                </span>

                <p className="text-white pt-3">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. "
                </p>
                <h5 className="mb-0 pb-0 text-white ">Palio Mehta</h5>
                <p className="mb-0 pb-0 text-white">Co, Founder</p>
              </div>

              <div className="item p-5">
                <span className="qoutes_box d-flex justify-content-center align-items-center">
                  <img src={QuoteIcon} alt="QuoteIcon" />
                </span>

                <p className="text-white pt-3">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. "
                </p>
                <h5 className="mb-0 pb-0 text-white ">Palio Mehta</h5>
                <p className="mb-0 pb-0 text-white">Co, Founder</p>
              </div>


              <div className="item p-5">
                <span className="qoutes_box d-flex justify-content-center align-items-center">
                  <img src={QuoteIcon} alt="QuoteIcon" />
                </span>

                <p className="text-white pt-3">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis. "
                </p>
                <h5 className="mb-0 pb-0 text-white ">Palio Mehta</h5>
                <p className="mb-0 pb-0 text-white">Co, Founder</p>
              </div>
            </OwlCarousel>
</motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TestimonialsDiv;
