import React, {useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import NavbarComp from "./NavbarComp";
import {initLightboxJS} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

function App() {
  useEffect(() => {
    initLightboxJS("Insert your License Key here", "Insert plan type here");
  }, []);



  return (
    <>

      <NavbarComp/>



    </>
  );
}

export default App;
