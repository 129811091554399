import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../VideoSection/VideoSection.css";
import VideoImg from "../../images/video-img.jpg";
import Button from "react-bootstrap/Button";
import VideoUrl from "../../video/Bhaynder_work.mp4";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";


const VideoSection = () => {
  const [isOpen, setOpen] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();
  const myanimation= useAnimation();


  useEffect(() => {
  

    if (inView) {
      animation.start({
        x: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.2,
        },
      });


      myanimation.start({
        x: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });

    }
    if (!inView) {
          animation.start({
        x: '-2vw',
        opacity:0

      })

      myanimation.start({
        x: '2vw',
        opacity:0
      })

    }


  }, [inView]);
  return (
    <>
      <ModalVideo
        channel="custom"
        isOpen={isOpen}
        url={VideoUrl}
        onClose={() => setOpen(false)}
      />

      <Container className="py-4">
        <Row className="d-flex align-items-center" ref={ref}>
          <Col lg="6">
            <motion.div
               animate={animation}
            >
              <h5 className="text-white">Show Video</h5>
              <h4 className="text-white">LUXURIA Home Decor</h4>
              <p className="text-white">
                Fine Interior Design To Build Vision & Passion
              </p>

              <a href="/" className="readMore ms-2 d-inline-block mt-2 mb-4">
                More Videos
              </a>
            </motion.div>
          </Col>
          <Col lg="6">

            <motion.div
             animate={myanimation}
            className="position-relative">
              <div className="position-absolute top-50 start-50 translate-middle">
                <Button
                  className="lvideo btn-animated"
                  onClick={() => setOpen(true)}
                >
                  <i></i>
                </Button>
              </div>

              <img
                src={VideoImg}
                alt="VideoImg"
                className="img-fluid w-100 border border-5"
              />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VideoSection;
