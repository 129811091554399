import React from "react";
import OwlCarousel from "react-owl-carousel";
import HomePro04 from "../../images/home-pro-04.jpg";
import HomePro05 from "../../images/home-pro-05.jpg";
import HomePro06 from "../../images/home-pro-06.jpg";

import "../HomeSlider/HomeSlider.css";

import HomeSliderTwoDetails from "./HomeSliderTwoDetails";

const HomeSliderTwo = () => {
  const HomeSlideTwo = [
    {
      name: "Corporate Office",
      img: `${HomePro04}`,
    },
    {
      name: "Retail Showroom",
      img: `${HomePro05}`,
    },
    {
      name: "Modular Kitchens",
      img: `${HomePro06}`,
    },
  ];

  //Owl Carousel Settings
  const options = {
    loop: false,
    margin: 15,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    navText: [
      "<i className='fa fa-caret-left'></i>",
      "<i className='fa fa-caret-right'></i>",
    ],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <OwlCarousel
        className="own_testimonials owl-carousel owl-theme"
        loop
        margin={10}
        {...options}
      >
        {HomeSlideTwo.map((HomeSliderDetailTwo, item) => {
          return (
            <HomeSliderTwoDetails
              HomeSliderDetailTwo={HomeSliderDetailTwo}
              key={item}
            />
          );
        })}
      </OwlCarousel>
    </>
  );
};

export default HomeSliderTwo;
