import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const HomeSliderTwoDetails = ({ HomeSliderDetailTwo }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 2,
        },
      });
    }
    if (!inView) {
      animation.start({
        y: "3vw",
        opacity: 0,
      });
    }
  }, [inView]);

  const { name, img } = HomeSliderDetailTwo;

  return (
    <>
      <motion.div animate={animation} className="item" ref={ref}>
        <div className="mb-0 mb-sm-3 rounded-0">
          <div className="middle">
            <h5 className="text text-white mb-0">{name}</h5>
          </div>
          <img src={img} alt="HomePro01" className="card-img-top rounded-0" />

          <div className="card-img-overlay">
            <div className="position-absolute bottom-15 start-50 translate-middle-x bg-white-50 w-100 reduce_opacity">
              <h5 className="card-title text-dark text-center p-2">{name}</h5>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default HomeSliderTwoDetails;
