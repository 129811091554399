import React, {useEffect} from 'react';

const Hospitality = () => {
  useEffect(() => {
    document.title = "Hospitality - Portfolio - Luxuria Home Decor";
  }, []);

  return (
    <div>
      <h1>Hospitality</h1>
    </div>
  )
}

export default Hospitality
