import React, {useEffect} from 'react';


const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Privacy Policy - Luxuria Home Decor";
      }, []);
  return (
    <>
        <h1>Privacy Policy</h1>
    </>
  )
}

export default PrivacyPolicy
