import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaSitemap } from 'react-icons/fa';
import '../Header/Header.css';

import {motion } from "framer-motion";


const Header = () => {





  return (
    <React.Fragment>


      <Container fluid className='top-header px-4 px-lg-5'>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center  text-white py-1">

              <motion.div
                initial={{opacity:0}}
                animate={{opacity:1 }}
                transition={{duration:0.6}}
                exit={{opacity:0}}
              > 
              <FaEnvelope />info@luxuriahomedecor.com <FaWhatsapp />+91 7718960701
              </motion.div>
             
   
              <motion.div 
               initial={{opacity:0}}
               animate={{opacity:1 }}
               transition={{duration:0.6}}
               exit={{opacity:0}}
              className='social_top_icon d-flex'>


              <motion.div whileHover={{scale:1.2}}>
             <FaFacebookF className='me-1 contactIcon' onClick={()=> window.open("http://www.facebook.com", "_blank")} /> 
             </motion.div>

             <motion.div whileHover={{scale:1.2}}>
             <FaInstagram className='me-2 contactIcon' onClick={()=> window.open("http://www.instagram.com", "_blank")}/>
             </motion.div>

             <motion.div>
              <FaSitemap />
              </motion.div>




              </motion.div>
            </div>

          </Col>

        </Row>
      </Container>

    </React.Fragment>
  )
}

export default Header
