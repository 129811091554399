import React from "react";
import OwlCarousel from "react-owl-carousel";
import HomePro01 from "../../images/home-pro-01.jpg";
import HomePro02 from "../../images/home-pro-02.jpg";
import HomePro03 from "../../images/home-pro-03.jpg";
import "../HomeSlider/HomeSlider.css";
import HomeSliderDetails from "./HomeSliderDetails";
import Col from "react-bootstrap/Col";
const HomeSlider = () => {


    
  const HomeSlide = [
    {
        name: 'Residential',
        img: `${HomePro01}`
    },
    {
        name: 'Commercial',
        img: `${HomePro02}`
    },
    {
        name: 'Hospitality',
        img: `${HomePro03}`
    },

]




  //Owl Carousel Settings
  const options = {
    loop: false,
    margin: 15,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    navText: [
      "<i className='fa fa-caret-left'></i>",
      "<i className='fa fa-caret-right'></i>",
    ],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
 <Col lg="12">
              <div className="heading_divider text-center mb-4">
                <h6 className="letter_spacing">OUR WORK</h6>
                <h2>Projects</h2>
              </div>
            </Col>


      <OwlCarousel
        className="own_testimonials owl-carousel owl-theme"
        loop
        margin={10}
        {...options}
      >

{
                    
            HomeSlide.map((HomeSliderDetail,item ) => {
                return (
                    <HomeSliderDetails HomeSliderDetail={HomeSliderDetail} key={item} />
                )
            })


}



        
      </OwlCarousel>
    </>
  );
};

export default HomeSlider;
