import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SliderOne from "../../images/slider-01.jpg";
import SliderTwo from "../../images/slider-02.jpg";
import SliderThree from "../../images/slider-03.jpg";
import "../Home/Home.css";
import HomeSlider from "../HomeSlider/HomeSlider";
import HomeSliderTwo from "../HomeSlider/HomeSliderTwo";
import WhatWeDo from "../WhatWeDo/WhatWeDo";
import VideoSection from "../VideoSection/VideoSection";
import WhyChoose from "../WhyChoose/WhyChoose";
import Testimonials from "../Testimonials/Testimonials";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const Home = () => {

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();
  const myanimation= useAnimation();


  useEffect(() => {
    // console.log("useEffect hook, inView =", inView);

    if (inView) {
      animation.start({
        x: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.2,
        },
      });


      myanimation.start({
        x: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });

    }
    if (!inView) {
          animation.start({
        x: '-2vw',
        opacity:0

      })

      myanimation.start({
        x: '2vw',
        opacity:0
      })

    }


  }, [inView]);

  useEffect(() => {
    document.title = "Home - Luxuria Home Decor";
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="custom_slider px-4 px-lg-5 mb-5">
        <Row>
          <Col lg="12">
            <Carousel fade>
              <Carousel.Item>
                <img src={SliderOne} alt="" className="d-block w-100" />
                <Carousel.Caption>
                  <h3 className="text-white">First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={SliderTwo} alt="" className="d-block w-100" />

                <Carousel.Caption>
                  <h3 className="text-white">Design Build Restaurants</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={SliderThree} alt="" className="d-block w-100" />

                <Carousel.Caption>
                  <h3 className="text-white">Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <section id="" className="home_about_section py-5" ref={ref}>
        <Container>
          <Row className="d-flex align-items-center" >
            <Col
           
              lg="5"
              md="5"
              sm="12"
              className="logo_element_home d-flex align-items-center px-4"
            >
              <motion.div
              animate={animation}
              className="d-flex flex-column  px-3"
              >
                <h6 className="d-block text-white letter_spacing">About</h6>
                <h3 className="d-block text-white">
                  <span className="fw-bold ">LUXURIA</span> Home Decor
                </h3>
              </motion.div>
            </Col>

            <Col lg="7"  md="7" sm="12" className="text-white">
            <motion.div
              animate={myanimation}
              >
              <h4>Top Interior Designing Consultancy In India</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra labore
                et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna lacus vel
                facilisis.
              </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="my-work" className="our_work py-5">
        <Container>
          <Row>
           

            <Col lg="12">
              <HomeSlider />
            </Col>

            <Col lg="12">
              <HomeSliderTwo />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="what-we-do">
        <WhatWeDo />
      </section>

      <section id="video-section" className="video_bg">
        <VideoSection />
      </section>

      <section id="why-choose-us" className="our_work py-4">
        <WhyChoose />
      </section>

      <section id="testimonials">
        <Testimonials />
      </section>
    </React.Fragment>
  );
};

export default Home;
