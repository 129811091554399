import React, {useEffect} from 'react';

const RetailShowroom = () => {
  useEffect(() => {
    document.title = "Retail Showroom - Portfolio - Luxuria Home Decor";
  }, []);
  return (
    <div>
        <h1>Retail Showroom</h1>
    </div>
  )
}

export default RetailShowroom
