import React, {useEffect} from 'react';

const ModularKitchens = () => {
  useEffect(() => {
    document.title = "Modular Kitchens - Portfolio - Luxuria Home Decor";
  }, []);

  return (
    <div>
        <h1>Modular Kitchens</h1>
    </div>
  )
}

export default ModularKitchens
