import React, {useEffect} from 'react';

const CorporateOffice = () => {
  useEffect(() => {
    document.title = "Corporate Office - Portfolio - Luxuria Home Decor";
  }, []);
  return (
    <div>
      <h1>Corporate Office</h1>
    </div>
  )
}

export default CorporateOffice
