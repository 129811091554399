import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactBanner from "../../images/contact-banner.jpg";
import "../Contact/Contact.css";

import {
  FaUserTie,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";
import { ImPhone } from "react-icons/im";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { RiHome8Fill } from 'react-icons/ri';

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;


const Contact = () => {

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const animation = useAnimation();
  const myanimation = useAnimation();


  useEffect(() => {
  


    

    if (inView) {
      animation.start({
        y: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 3
        },
      });

      myanimation.start({
        x: 0,
        opacity:1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.2,
        }
      });


    }
    if (!inView) {
          animation.start({
        y: '5vw',
        opacity:0

      });

      myanimation.start({
        x: '-10vw',
        opacity:0

      });

    
    }


  }, [inView]);



  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);

  //form state
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  //form event

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, phone, email, msg);
    const data = {
      Name: name,
      Phone: phone,
      Email: email,
      Msg: msg,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/4fdd13fe-865d-4c66-ad00-61a08bc22040",
        data
      )
      .then((response) => {
        console.log(response);
        setName("");
        setPhone("");
        setEmail("");
        setMsg("");
      });
  };

  useEffect(() => {
    document.title = "Contact - Luxuria Home Decor";
  }, []);

  return (
    <>

<motion.div
     initial={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity:0}}
     >
      <Container fluid className=" px-4 px-lg-5 mb-5 "  >
        <Row className=" about_bannerBg g-0  d-flex align-items-center position-relative">
          <Col sm={4} className="h-100  align-items-center" >
            <motion.div
            
            className="ps-5 text-white">
              <h1>Contact</h1>
              <h3 className="fw-light">
                Fill up the given form or email us and we will get back to you
                within <br />
                24 hours.
              </h3>
            </motion.div>
            <div className="position-absolute bottom-0 start-0 breadcrumb-bg">
    <Breadcrumb className=" pt-3 ps-3 pe-3">
      <Breadcrumb.Item href="#"><RiHome8Fill/></Breadcrumb.Item>
        <Breadcrumb.Item active>Contact</Breadcrumb.Item>
    </Breadcrumb>    
  </div>
          </Col>
          <Col sm={8} className="">
          <motion.div
                    initial={false}
                    animate={
                      isLoaded && isInView
                        ? {
                            WebkitMaskImage: visibleMask,
                            maskImage: visibleMask,
                          }
                        : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                    }
                    transition={{ duration: 1, delay: 0.5 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setIsInView(true)}
                  >
            <img  onLoad={() => setIsLoaded(true)} src={ContactBanner} alt="" className="w-100 img-fluid" />
                      </motion.div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="d-flex align-items-center">
          <Col lg={6}>
            <motion.div
                         >
              <h3>Get in Touch</h3>
              <h5 className="fw-light">WRITE, CHAT, OR CALL</h5>
              <hr />
              <h6 className="mb-4">
               <FaUserTie /> Mr. Vimal Bakraniya
              </h6>
              <h6 className="mb-4">
               <ImPhone /> +91 7718960701
              </h6>
              <h6>
              <FaEnvelope /> info@luxuriahomedecor.com
              </h6>

              <div>
                <hr />
                <h5>Join Us On Our Journey</h5>

                <ul className="social-set">
                 
                  <li>
                    <a className="sociali" href="https://www.facebook.com/">
                      <FaFacebookF />
                    </a>
                  </li>

                  <li>
                    <a className="sociali" href="https://www.instagram.com/">
                      <FaInstagram />
                    </a>
                  </li>

                </ul>
              </div>

            </motion.div>
          </Col>
          <Col lg={6}>
            <section className="jumbotron  position-relative">
              <iframe
                title="myFrame"
                width="100%"
                height="350px"
                rel="noreferrer"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30125.246189669895!2d72.83813636987324!3d19.29733004498848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b19c562b3b19%3A0x6a03f8b31cdd7995!2sMaxus%20Mall!5e0!3m2!1sen!2sin!4v1695451275997!5m2!1sen!2sin"
              ></iframe>
            </section>
          </Col>
        </Row>
      </Container>

      <section id="form-section" className="mt-4" ref={ref}>
        <Container fluid className=" px-4 px-lg-5">
          <div className="contact-bg-form pt-5">
            <Container>
              <Row>
                <Col lg={8} className="mx-auto text-center">
                  <motion.div className="feedback_bg p-4"
                                           animate={animation}
                                           >
                    <h2 className="mt-4 mb-3">Send us a message</h2>

                    <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Row className="mb-3 px-5">

                    <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Your Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="rounded-0"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                className="rounded-0"
                  type="text"
                  placeholder="Last Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Control
                  className="rounded-0"
                  type="text"
                  placeholder="Phone Number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Control
                className="rounded-0"
                  type="email"
                  placeholder="Email Id"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </Form.Group>
              </Col>


              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                 className="rounded-0"
                  as="textarea"
                  rows={5}
                  onChange={(e) => setMsg(e.target.value)}
                  value={msg}
                  placeholder="Write Message..."
                />
              </Form.Group>

              </Row>
            </Form>

            <Button  className="rounded-0 custom-button fs-4" type="submit" onClick={handleSubmit}  size="lg">
              Submit
            </Button>



                  </motion.div>
            

                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>

</motion.div>
    </>
  );
};

export default Contact;
